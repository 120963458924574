@tailwind base;
@tailwind components;
@tailwind utilities;

a {
  color: inherit;
  text-decoration: none;
}

body {
  background-color: #f5f5f5;
}

@font-face {
  font-family: "poppins";
  src: url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;400;500;700;800&display=swap");
}

* {
  box-sizing: border-box;
}

.bg-white {
  background-color: white;
}
.bg-whitesmoke {
  background-color: whitesmoke;
}
